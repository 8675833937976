import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {ProjectEnquiryProvider} from "../../context/ProjectEnquiryContext";

const Layout = ({ data, children }) => {
    return (<>
        <GoogleReCaptchaProvider reCaptchaKey="6LdpxosdAAAAAFenROCUFSGek6GYrHvH7MiQ3zVT">
            <ProjectEnquiryProvider>
                <Header data={data} />
                <>{children}</>
                <Footer />
            </ProjectEnquiryProvider>
        </GoogleReCaptchaProvider>
    </>)
};

export default Layout;
