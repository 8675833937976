exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aria-club-july-2022-js": () => import("./../../../src/pages/aria-club-july-2022.js" /* webpackChunkName: "component---src-pages-aria-club-july-2022-js" */),
  "component---src-pages-aria-family-js": () => import("./../../../src/pages/aria-family.js" /* webpackChunkName: "component---src-pages-aria-family-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-email-privacy-js": () => import("./../../../src/pages/email-privacy.js" /* webpackChunkName: "component---src-pages-email-privacy-js" */),
  "component---src-pages-enquiry-js": () => import("./../../../src/pages/enquiry.js" /* webpackChunkName: "component---src-pages-enquiry-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-foundation-js": () => import("./../../../src/pages/our-foundation.js" /* webpackChunkName: "component---src-pages-our-foundation-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-public-realm-js": () => import("./../../../src/pages/projects/public-realm.js" /* webpackChunkName: "component---src-pages-projects-public-realm-js" */),
  "component---src-pages-projects-residential-js": () => import("./../../../src/pages/projects/residential.js" /* webpackChunkName: "component---src-pages-projects-residential-js" */),
  "component---src-pages-projects-retail-js": () => import("./../../../src/pages/projects/retail.js" /* webpackChunkName: "component---src-pages-projects-retail-js" */),
  "component---src-pages-property-buyers-privacy-js": () => import("./../../../src/pages/property-buyers-privacy.js" /* webpackChunkName: "component---src-pages-property-buyers-privacy-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

