import React, { useState, createContext } from "react";

export const ProjectEnquiryContext = createContext();

export const ProjectEnquiryProvider = ({ children }) => {
    const [isProjectNavOpened, setIsNavOpen] = useState(false);

    const handleProjectNav = () => {
        setIsNavOpen(!isProjectNavOpened);
    };

    return (
        <ProjectEnquiryContext.Provider
            value={{
                isProjectNavOpened,
                handleProjectNav,
            }}
        >
            {children}
        </ProjectEnquiryContext.Provider>
    );
};