import React from 'react';
import { useInView } from 'react-intersection-observer';

export default function FadeIn({ children, className }) {
  const { ref, inView } = useInView({
    rootMargin: '-100px 0px',
    triggerOnce: true,
  });

  return (
    <section
      ref={ref}
      className={`${
        inView ? "in-view" : ""
      }`}
    >
      {children}
    </section>
  )
}